import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, CircularProgress, Grid, MenuItem, IconButton, Typography, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import userService from '../services/userservice';
import { useAuth } from '../context/authContext';
import incaricoService from '../services/incaricoservice';
import ErrorDialog from '../errorDialog';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

const CreateUser = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openDialogError, setOpenDialogError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [dataNascita, setDataNascita] = useState('');
    const [incarico, setIncarico] = useState('');
    const [cellulare, setCellulare] = useState('');
    const [titleDialog, setTitleDialog] = useState('');   
    const [errors, setErrors] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [incarichi, setIncarichi] = useState([]);
    const { saveUser, saveToken } = useAuth() || {};
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));
    const maxDateString = maxDate.toISOString().split('T')[0];
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { token } = useAuth() || {};
    const [username, setUsername] = useState('');

    const validatePassword = (password, username) => {
        const specialChars = /[!#$%&()*+,\-.:;=?@^_]/;
        const lengthValid = password.length >= 8 && password.length <= 30;
        const upperCaseValid = /[A-Z]/.test(password);
        const lowerCaseValid = /[a-z]/.test(password);
        const numberValid = /\d/.test(password);
        const specialCharValid = specialChars.test(password);
       
        const containsUsernamePart = (password, username) => {
            if (!username) return false; 
            const minLength = 3;
            for (let i = 0; i <= username.length - minLength; i++) {
                const substring = username.slice(i, i + minLength);
                if (password.includes(substring)) return true;
            }
            return false;
        };

        const usernameIncluded = containsUsernamePart(password, username);


        if (!lengthValid) return 'La password deve contenere tra 8 e 30 caratteri.';
        if (!upperCaseValid) return 'La password deve contenere almeno una lettera maiuscola.';
        if (!lowerCaseValid) return 'La password deve contenere almeno una lettera minuscola.';
        if (!numberValid) return 'La password deve contenere almeno un numero.';
        if (!specialCharValid) return 'La password deve contenere almeno un carattere speciale.';
        if (usernameIncluded) return 'La password non può contenere l\'username o parti di esso.';

        return null;
    };


    useEffect(() => {
        const getIncarichi = async () => {
            try {
                const response = await incaricoService.getAll();
                setIncarichi(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                setIncarichi([]);
            }
        };

        getIncarichi();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        try {
            const createUser = {
                name: nome,
                email: email,
                surname:cognome,
                password: btoa(password),
                nomeIncarico: incarico,
                dataDiNascita: dataNascita,
                phone: cellulare,
                username:username,
                role:role
            };

            const response = await userService.createUserBackOffice(createUser, token);

            if (response.statusCodeValue == 202) {
                setTitleDialog('Success');
                setMessageError(response.message);
                setOpenDialogError(true);
            }

        } catch (error) {
            const tempErrors = {};

            if (error && error.response.data.statusCodeValue === 401) {
                saveToken(null);
                saveUser(null, false);
                navigate('/login')
            }

            if (error && error.response.data.message === "L'email e' gia' esistente") {
                tempErrors.email = error.response.data.message;
                setErrors(tempErrors);
            } else {
                setMessageError(error.response.data.message);
                setTitleDialog('Attenzione');
                setOpenDialogError(true);
            }
        }

    };

    const validateForm = () => {
        let tempErrors = {};
        const user = email != '' && email != null ? email : username;
        const passwordPattern = validatePassword(password, user);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const namePattern = /^[a-zA-ZÀ-ÿ' ]+$/;
        const phonePattern = /^[0-9]{9,11}$/;

        if (!email && !username) {
            tempErrors.email = "L'email è obbligatoria, se non inserisci lo username";
        } else if (!emailPattern.test(email) && !username) {
            tempErrors.email = "L'email non è valida";
        }

        if(!username && !email){
            tempErrors.username="Lo username è obbligatorio, se non inserisci la mail"
        }

        if (passwordPattern != null) {
            tempErrors.password = passwordPattern;
        }

        if (password !== confirmPassword) {
            tempErrors.confirmPassword = "Le password non corrispondono";
        }

        if(username){
            if(!namePattern.test(username)){
                tempErrors.username = 'Username non valido, sono accettati solo caratteri alfabetici';
            }
        }

        if (!nome) {
            tempErrors.nome = "Il nome è obbligatorio";
        } else if (!namePattern.test(nome)) {
            tempErrors.nome = "Il nome può contenere solo lettere e apostrofi";
        }

        if (!cognome) {
            tempErrors.cognome = "Il cognome è obbligatorio";
        } else if (!namePattern.test(cognome)) {
            tempErrors.cognome = "Il cognome può contenere solo lettere e apostrofi";
        }

        if (!dataNascita) {
            tempErrors.dataNascita = "La data di nascita è obbligatoria";
        } else {
            const birthYear = new Date(dataNascita).getFullYear();
            const currentYear = new Date().getFullYear();

            if (birthYear > currentYear) {
                tempErrors.dataNascita = "L'anno di nascita non può essere nel futuro";
            } else if (birthYear < 1900) {
                tempErrors.dataNascita = "L'anno di nascita non può essere inferiore al 1900";
            } else if (!validateAge(dataNascita)) {
                tempErrors.dataNascita = "Devi essere maggiorenne per completare la registrazione";
            }
        }

        if (!incarico) {
            tempErrors.incarico = "L'incarico è obbligatorio";
        }

        if (!role) {
            tempErrors.role = "Il ruolo è obbligatorio";
        }

        if (!cellulare) {
            tempErrors.cellulare = "Il cellulare è obbligatorio";
        } else if (!phonePattern.test(cellulare)) {
            tempErrors.cellulare = "Il cellulare deve contenere dai 9 a 11 caratteri numerici";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const validateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age >= 18;
    };

    const handleCancelErrorExit = () => {
        setOpenDialogError(false);
    };

    return (
        <Box component="form" noValidate sx={{ width: '100%', mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        label="Ruolo"
                        required
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        error={!!errors.role}
                        helperText={errors.role}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="user">User</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nome"
                        required
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        error={!!errors.nome}
                        helperText={errors.nome}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Cognome"
                        required
                        value={cognome}
                        onChange={(e) => setCognome(e.target.value)}
                        error={!!errors.cognome}
                        helperText={errors.cognome}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Data di Nascita"
                        required
                        type="date"
                        value={dataNascita}
                        onChange={(e) => setDataNascita(e.target.value)}
                        error={!!errors.dataNascita}
                        helperText={errors.dataNascita}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                        inputProps={{
                            max: maxDateString
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        label="Professione"
                        required
                        value={incarico}
                        onChange={(e) => setIncarico(e.target.value)}
                        error={!!errors.incarico}
                        helperText={errors.incarico}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    >
                        {incarichi.map((incarico) => (
                            <MenuItem key={incarico.idIncarico} value={incarico.nomeIncarico}>
                                {incarico.nomeIncarico}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Cellulare"
                        required
                        value={cellulare}
                        onChange={(e) => setCellulare(e.target.value)}
                        error={!!errors.cellulare}
                        helperText={errors.cellulare}
                        inputProps={{ maxLength: 11 }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}

                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="E-mail"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                        inputProps={{ maxLength: 50, }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                        sx={{
                            backgroundColor: username != null && username != '' ? '#f0f0f0' : 'transparent',
                            '& .MuiInputBase-root': {
                                backgroundColor: username != null && username != '' ? '#f0f0f0' : 'transparent',
                            }
                        }}
                        disabled={username != null && username != '' ? true : false}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={!!errors.username}
                        helperText={errors.username}
                        inputProps={{ maxLength: 50, }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                        sx={{
                            backgroundColor: email != null && email != '' ? '#f0f0f0' : 'transparent',
                            '& .MuiInputBase-root': {
                                backgroundColor: email != null && email != '' ? '#f0f0f0' : 'transparent',
                            }
                        }}
                        disabled={email != '' && email != null ? true : false}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            ),
                        }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="confirmPassword"
                        label="Conferma Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            ),
                        }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    />
                </Grid>

            </Grid>


            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button disabled={true}/>
                <Button
                    type="submit"
                    variant="contained"
                    color="warning"
                    sx={{ width: '26%', color: 'black', background: '#FDC800' }}
                    onClick={handleSubmit}
                >
                    SALVA
                </Button>
            </Box>
            <ErrorDialog
                onClose={handleCancelErrorExit}
                open={openDialogError}
                title={titleDialog}
                message={messageError}
            />
        </Box>
    );
};

export default CreateUser;
