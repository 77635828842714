import axios from 'axios';
import api from '../../utils/proxy.js';

const paymentService = {
    token: async () => {
        try {
            const response = await axios.post(api.path + api.token, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response; 
        } catch (error) {
            throw error;
        }
    },
    payment: async (data) =>{
        try{

            const response = await axios.post(api.path + api.checkout, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;

        }catch(error){
            throw error;
        }
    },
    subscribe: async(data)=>{
        try{

            const response = await axios.post(api.path + api.subscription, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;

        }catch(error){
            throw error;
        }
    },
    subscribeDelete: async(data)=>{
        try{

            const response = await axios.post(api.path + api.subscriptionDelete, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;

        }catch(error){
            throw error;
        }
    },
    getClient: async(data)=>{
        try{
            const response = await axios.post(api.path + api.getClient, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;

        }catch(error){
            throw error;
        }
    },
    emailPaymentReject: async(data)=>{
        try{
            const response = await axios.post(api.path + api.emailReject, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;

        }catch(error){
            throw error;
        }
    },
    checkPrice: async (data) =>{
        try{

            const response = await axios.post(api.path + api.checkPrice, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;

        }catch(error){
            throw error;
        }
    },
};

export default paymentService;
