import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, IconButton, Box, CircularProgress, Tooltip, Pagination } from '@mui/material';
import { Edit, Delete, CheckCircleOutlineOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import catalogService from '../services/catalogservice';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../dialog';

const TableCode = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [maxNumPage, setMaxNumPage] = useState(0);
    const { token } = useAuth() || {};
    const { saveUser, saveToken } = useAuth() || {};
    const [id, setId] = useState('');
    const [dialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const maxCaratteri = 25;
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    useEffect(() => {
        const fetchFilteredCodes = async () => {
            setLoading(true);
            try {
                const data = {
                    page: page + 1,
                    limit: limit,
                    search: debouncedSearchTerm,
                };

                const response = await catalogService.getAllCode(data, token);

                if (response.statusCodeValue === 401) {
                    saveToken(null, null);
                    saveUser(null, false);
                    navigate('/login');
                    return;
                }

                setData(response.data.codes || []);
                setFilteredData(response.data.codes || []);
                setMaxNumPage(response.data.numMaxPage || 0);
            } catch (error) {
                if (error?.response?.data?.statusCodeValue === 401) {
                    saveToken(null, null);
                    saveUser(null, false);
                    navigate('/login');
                } else {
                    return;
                }
            } finally {
                setLoading(false);
            }
        };

        if (debouncedSearchTerm || debouncedSearchTerm === '') {
            fetchFilteredCodes();
        }
    }, [debouncedSearchTerm, page, limit, token]);


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 500);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    return (
        <Box sx={{ height: '100%', overflowY: 'auto', border: '1px solid #ddd', borderRadius: '4px', padding: '16px' }}>
            <TextField
                label="Cerca codice"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: '16px' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputLabelProps={{
                    style: { fontSize: '16px', color: '#666', top: '5px' }
                }}
            />

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Codice</TableCell>
                            <TableCell>Attivo</TableCell>
                            <TableCell>Usato Da</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ fontFamily: 'Ruffina' }}>
                        {filteredData.map((user) => (
                            <TableRow key={user.code}>
                                <TableCell>
                                    <Tooltip title={user.code} arrow>
                                        <span>
                                            {user.code.length > 25 ? `${user.code.substring(0, 25)}...` : user.code}
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ alignItems: 'center' }}>
                                    <Tooltip
                                        title={user.isActive === 0 ? 'Codice attivo' : 'Codice non attivo'}
                                        arrow
                                    >
                                        {user.isActive === 0 ? (
                                            <CheckCircleOutlineOutlined color="success" />
                                        ) : (
                                            <ErrorOutlineOutlined color="error" />
                                        )}
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={user.email || ''} arrow>
                                        <span>
                                            {user.email && user.email.length > 25
                                                ? `${user.email.substring(0, 25)}...`
                                                : user.email || ''}
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}

            <Pagination
                sx={{ marginTop: '30px' }}
                count={maxNumPage}
                page={page + 1} 
                onChange={(event, newPage) => setPage(newPage - 1)}
            />


        </Box>
    );
};

export default TableCode;
