import React from 'react';
import { Container, Box } from '@mui/material';
import Header from '../header';
import LoginForm from './loginForm';
import Footer from '../footer';

const LoginPage = () => {
    return (
        <Box sx={{ width: '100vw', overflowX: 'hidden' }}>
            <Header sx={{ display: 'flex', justifyContent: 'center', overflowX: 'hidden' }} />
            <Box sx={{ marginTop: 8, display: 'flex', justifyContent: 'center', overflowX: 'hidden', alignContent: 'center', pr: 3 }}>
                <Box sx={{ width: '100%', maxWidth: '800px', overflowX: 'hidden' }}>
                    <LoginForm />
                </Box>

            </Box>
            <Box sx={{ marginTop: 45, display: 'flex', justifyContent: 'center', overflowX: 'hidden' }}>
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Footer />
                </Box>

            </Box>

        </Box>
    );
};

export default LoginPage;
