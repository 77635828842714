import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Box, Button, Grid, IconButton, /* MenuItem, */ TextField, Typography } from '@mui/material';
import LoginHeader from '../header';
import Footer from '../footer';
import '../../css/style.css';
import accountService from '../services/accountservice'
import { Link, useNavigate } from 'react-router-dom';
import { RegistrationContext } from '../context/registrationProvider';
import ConfirmationDialog from '../dialog';
import { VisibilityOutlined, VisibilityOffOutlined, InfoOutlined, /* Directions, */ CheckCircleOutlineOutlined, HighlightOffOutlined } from '@mui/icons-material';
import ErrorDialog from '../errorDialog';
import debounce from 'lodash.debounce';
import api from '../../utils/proxy';

const UserRegistrationLogin = () => {
    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const [email, setEmail] = useState(userData.email ?? '');
    const [password, setPassword] = useState(userData.password ?? '');
    const [confirmPassword, setConfirmPassword] = useState(userData.confirmPassword ?? '');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogError, setOpenDialogError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [errors, setErrors] = useState({});
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false,
        usernameNotIncluded: false
    });


    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        if (!canAccessStepRegistration) {
            navigate('/register/login');
        }
    }, [canAccessStepRegistration, navigate]);

    const validateForm = () => {
        let tempErrors = {};
        const passwordPattern = validatePassword(password, email);
        const emailPattern = /^[^\s@]+@[^\s@]{2,}\.[^\s@]{2,}$/;

        if (!email) {
            tempErrors.email = "L'email è obbligatoria";
        } else if (!emailPattern.test(email)) {
            tempErrors.email = "L'email non è valida";
        }

        if (passwordPattern != null) {
            tempErrors.password = passwordPattern;
        }

        if (password !== confirmPassword) {
            tempErrors.confirmPassword = "Le password non corrispondono";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const validatePassword = (password, username) => {
        const specialChars = /[!#$%&()*+,\-.:;=?@^_]/;
        const lengthValid = password.length >= 8 && password.length <= 30;
        const upperCaseValid = /[A-Z]/.test(password);
        const lowerCaseValid = /[a-z]/.test(password);
        const numberValid = /\d/.test(password);
        const specialCharValid = specialChars.test(password);


        const containsUsernamePart = (password, username) => {
            if (!username) return false;
            const minLength = 3;
            for (let i = 0; i <= username.length - minLength; i++) {
                const substring = username.slice(i, i + minLength);
                if (password.includes(substring)) return true;
            }
            return false;
        };

        const usernameIncluded = containsUsernamePart(password, username);

        if (!password) {
            setPasswordCriteria({
                length: false,
                upperCase: false,
                lowerCase: false,
                number: false,
                specialChar: false,
                usernameNotIncluded: false
            });
            return;
        }
        setPasswordCriteria({
            length: lengthValid,
            upperCase: upperCaseValid,
            lowerCase: lowerCaseValid,
            number: numberValid,
            specialChar: specialCharValid,
            usernameNotIncluded: !usernameIncluded
        });

        if (!lengthValid) return 'La password deve contenere tra 8 e 30 caratteri.';
        if (!upperCaseValid) return 'La password deve contenere almeno una lettera maiuscola.';
        if (!lowerCaseValid) return 'La password deve contenere almeno una lettera minuscola.';
        if (!numberValid) return 'La password deve contenere almeno un numero.';
        if (!specialCharValid) return 'La password deve contenere almeno un carattere speciale tra quelli amessi.';
        if (usernameIncluded) return 'La password non può contenere l\'username o parti di esso.';

        return null;
    };

    const debouncedValidatePassword = useCallback(
        debounce((password, username) => validatePassword(password, username), 500),
        []
    );

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        debouncedValidatePassword(newPassword, email);
    };

    const handleSubmit = async () => {

        if (!validateForm()) {
            return;
        }

        if (userData.externalId) {
            allowStepRegistrationAccess();
            setUserData({
                nome: userData.nome,
                cognome: userData.cognome,
                dataNascita: userData.dataNascita,
                incarico: userData.incarico,
                cellulare: userData.cellulare,
                email: email,
                password: btoa(password),
                confirmPassword: btoa(password),
                externalId: userData.externalId,
                idAccount: userData.idAccount
            });

            /**
             * In questo caso mi chiamo il servizio di modifica
             */
            if (validateForm()) {

                setUserData({
                    nome: userData.nome,
                    cognome: userData.cognome,
                    dataNascita: userData.dataNascita,
                    incarico: userData.incarico,
                    cellulare: userData.cellulare,
                    email: email,
                    password: btoa(password),
                    confirmPassword: btoa(password),
                    externalId: userData.externalId,
                    idAccount: userData.idAccount
                });


                const data = {
                    name: userData.nome,
                    surname: userData.cognome,
                    email: email,
                    password: btoa(password),
                    nomeIncarico: userData.incarico,
                    dataDiNascita: userData.dataNascita,
                    phone: userData.cellulare,
                    role: 'user',
                    externalKey: userData.externalId
                }

                try {

                    const response = await accountService.update(data);

                    setUserData({
                        nome: userData.nome,
                        cognome: userData.cognome,
                        dataNascita: userData.dataNascita,
                        incarico: userData.incarico,
                        cellulare: userData.cellulare,
                        email: email,
                        password: btoa(password),
                        confirmPassword: btoa(password),
                        externalId: userData.externalId,
                        idAccount: userData.idAccount
                    });



                    if (response.statusCodeValue === 202) {
                        allowStepRegistrationAccess();
                        navigate('/register/choice');
                    }

                } catch (error) {
                    let tempErrors = {};

                    if (error.response !== undefined) {

                        if (error.response.data.message == "L'account e' gia' esistente") {
                            tempErrors.email = 'L\'email e\' gia\' esistente';
                            setErrors(tempErrors);
                            return;
                        } else {
                            setMessageError(error.response.data.message ?? 'Riprovare piu\' tardi');
                            setOpenDialogError(true);
                        }


                    }

                }
            }

            return;
        }

        if (validateForm()) {

            setUserData({
                nome: userData.nome,
                cognome: userData.cognome,
                dataNascita: userData.dataNascita,
                incarico: userData.incarico,
                cellulare: userData.cellulare,
                email: email,
                password: btoa(password),
                confirmPassword: btoa(password)
            });


            const data = {
                name: userData.nome,
                surname: userData.cognome,
                email: email,
                password: btoa(password),
                nomeIncarico: userData.incarico,
                dataDiNascita: userData.dataNascita,
                phone: userData.cellulare,
                role: 'user'
            }

            try {

                const response = await accountService.register(data);
                setUserData({
                    idAccount: response.idAccount,
                    idAccountBraintree: response.idAccountBraintree,
                    externalId: response.externalKey,
                    nome: userData.nome,
                    cognome: userData.cognome,
                    email: email,
                    password: btoa(password),
                    incarico: userData.incarico,
                    dataNascita: userData.dataNascita,
                    cellulare: userData.cellulare
                });


                if (response.statusCodeValue === 201) {
                    allowStepRegistrationAccess();
                    navigate('/register/choice');
                }

            } catch (error) {
                let tempErrors = {};

                if (error.response !== undefined) {

                    if (error.response.data.message == "L'account e' gia' esistente") {
                        tempErrors.email = 'L\'email e\' gia\' esistente';
                        setErrors(tempErrors);
                        return;
                    } else {
                        setMessageError(error.response.data.message ?? 'Riprovare piu\' tardi');
                        setOpenDialogError(true);
                    }


                }

            }
        }
    };

    const handleBackButtonClick = () => {
        setOpenDialog(true);
    };

    const handleConfirmExit = () => {
        setUserData({
            nome: userData.nome,
            cognome: userData.cognome,
            dataNascita: userData.dataNascita,
            incarico: userData.incarico,
            cellulare: userData.cellulare,
            email: '',
            password: '',
            confirmPassword: ''
        });
        setOpenDialog(false);
        navigate('/registration/user');
    };

    const handleCancelExit = () => {
        setOpenDialog(false);
    };
    const handleCancelErrorExit = () => {
        setOpenDialogError(false);
    };

    const isFormValid = () => {
        return (
            email.trim() !== '' &&
            password.trim() !== '' &&
            confirmPassword.trim() !== ''
        );
    };


    return (
        <>
            <LoginHeader isRegister={true} />
            <Box
                sx={{
                    maxWidth: 492,
                    mx: 'auto',
                    px: 0,
                    pr: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0,
                    mb: 25,
                    overflowX: 'hidden',
                    pr: 3.3
                }}
            >
                <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 2, mt: 8, fontFamily: 'Open Sans' }}>
                    Passaggio 1 di 3
                </Typography>

                <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Rufina', fontWeight: '400', fontSize: '34px', letterSpacing: '1px' }}>
                    Crea la tua password
                </Typography>

                <Box component="form" noValidate sx={{ width: '100%', mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="E-mail"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={!!errors.email}
                                helperText={errors.email}
                                inputProps={{ maxLength: 50, }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{ width: '492px' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="password"
                                label="Password"
                                required
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                margin="normal"
                                value={password}
                                onChange={handlePasswordChange}
                                error={!!errors.password}
                                helperText={errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                        </IconButton>
                                    ),
                                    style: {
                                        borderColor: passwordCriteria.length && passwordCriteria.upperCase && passwordCriteria.lowerCase && passwordCriteria.number && passwordCriteria.specialChar && passwordCriteria.usernameNotIncluded ? 'green' : ''
                                    }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{
                                    borderColor: passwordCriteria.length && passwordCriteria.upperCase && passwordCriteria.lowerCase && passwordCriteria.number && passwordCriteria.specialChar && passwordCriteria.usernameNotIncluded ? 'green' : '',
                                    width: '492px',
                                }}

                            />


                            <Box sx={{
                                backgroundColor: '#E5F6FD',
                                borderRadius: '5px',
                                p: 2,
                                mt: 1,
                                display: passwordCriteria.length && passwordCriteria.upperCase && passwordCriteria.lowerCase && passwordCriteria.number && passwordCriteria.specialChar && passwordCriteria.usernameNotIncluded ? 'none' : 'flex',
                                flexDirection: 'column'
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <InfoOutlined sx={{ color: '#0587D1', mr: 1 }} />
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#014361' }}>
                                        La password deve rispettare i seguenti criteri:
                                    </Typography>
                                </Box>

                                <Box sx={{ fontSize: '14px', color: '#014361', marginLeft: '30px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: passwordCriteria.length ? 'bold' : 'normal', color: passwordCriteria.length ? 'black' : 'black', gap: '8px' }}>
                                        {passwordCriteria.length ? <CheckCircleOutlineOutlined /> : <HighlightOffOutlined />} Deve essere lunga almeno 8 caratteri e non più di 30
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: passwordCriteria.upperCase ? 'bold' : 'normal', color: passwordCriteria.upperCase ? 'black' : 'black', gap: '8px' }}>
                                        {passwordCriteria.upperCase ? <CheckCircleOutlineOutlined /> : <HighlightOffOutlined />} Deve contenere almeno una lettera maiuscola
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: passwordCriteria.lowerCase ? 'bold' : 'normal', color: passwordCriteria.lowerCase ? 'black' : 'black', gap: '8px' }}>
                                        {passwordCriteria.lowerCase ? <CheckCircleOutlineOutlined /> : <HighlightOffOutlined />} Deve contenere almeno una lettera minuscola
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: passwordCriteria.number ? 'bold' : 'normal', color: passwordCriteria.number ? 'black' : 'black', gap: '8px' }}>
                                        {passwordCriteria.number ? <CheckCircleOutlineOutlined /> : <HighlightOffOutlined />} Deve contenere almeno un numero
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: passwordCriteria.specialChar ? 'bold' : 'normal', color: passwordCriteria.specialChar ? 'black' : 'black', gap: '8px' }}>
                                        {passwordCriteria.specialChar ? <CheckCircleOutlineOutlined /> : <HighlightOffOutlined />} Deve contenere almeno un carattere speciale tra quelli ammessi (!#$%&()*+,-.:;=?@^_)
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: passwordCriteria.usernameNotIncluded ? 'bold' : 'normal', color: passwordCriteria.usernameNotIncluded ? 'black' : 'black', gap: '8px' }}>
                                        {passwordCriteria.usernameNotIncluded ? <CheckCircleOutlineOutlined /> : <HighlightOffOutlined />} Non può contenere l'email o parti di esso
                                    </div>
                                </Box>
                            </Box>

                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                name="confirmPassword"
                                label="Conferma Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                fullWidth
                                required
                                margin="normal"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                        </IconButton>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{ width: '492px' }}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="body2" align="left" sx={{ color: 'gray', mt: 3, mb: 2 }}>
                        Registrandoti, accetti i nostri{' '}
                        <Link
                            component="button"
                            onClick={() => window.location.href = api.vaticano + '/privacy-policy'}
                            style={{ color: 'inherit', textDecoration: 'underline' }}
                            target="_blank"
                            rel="noopener"
                        >
                            Termini e Condizioni
                        </Link>{' '}
                        e confermi di aver letto la nostra{' '}
                        <Link
                            component="button"
                            onClick={() => window.location.href = api.vaticano + '/privacy-policy'}
                            style={{ color: 'inherit', textDecoration: 'underline' }}
                            target="_blank"
                            rel="noopener"
                        >
                            Privacy Policy
                        </Link>.                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, mb: 12 }}>
                        <Button
                            variant="outlined"
                            color=""
                            sx={{ width: '26%', color: 'black', background: 'white' }}
                            onClick={handleBackButtonClick}
                        >
                            INDIETRO
                        </Button>
                        <Button
                            variant="outlined"

                            sx={{
                                width: '26%',
                                color: isFormValid() ? '#000000' : 'gray',
                                background: isFormValid() ? '#FDC800' : '#E0E0E0',
                                cursor: isFormValid() ? 'pointer' : 'not-allowed',
                            }}
                            onClick={handleSubmit}
                            disabled={!isFormValid()}

                        >
                            CONTINUA
                        </Button>
                    </Box>
                </Box>

                <ConfirmationDialog
                    open={openDialog}
                    onClose={handleCancelExit}
                    onConfirm={handleConfirmExit}
                    title="Uscire dalla registrazione?"
                    message="Interrompendo il processo di registrazione perderai i dati inseriti fin qui e dovrai iniziare nuovamente."
                />

                <ErrorDialog
                    onClose={handleCancelErrorExit}
                    open={openDialogError}
                    title="Attenzione"
                    message={messageError}
                />
            </Box>
            <Footer />
        </>
    );
};

export default UserRegistrationLogin;
