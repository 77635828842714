import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const saveUser = (userData, rememberMe) => {
    setUser(userData);
    setRememberMe(rememberMe);
  };

  const saveToken = (token, role, expiresIn) => {
    setToken(token);
    setRole(role);
    const expirationTime = expiresIn * 1000;
    localStorage.setItem("tokenExpiration", expirationTime.toString());
  };

  const logout = () => {
    setUser(null);
    setRememberMe(false);
    setToken(null);
    setRole("");
    localStorage.removeItem("tokenExpiration");
    navigate("/login");
  };

  useEffect(() => {
    if (token) {
      const checkSession = () => {
        const expiration = localStorage.getItem("tokenExpiration");
        if (expiration) {
          const expirationTime = parseInt(expiration, 10);
          const currentTime = Date.now();
          if (currentTime >= expirationTime) {
            logout();
          }
        }
      };

      const interval = setInterval(checkSession, 5000);
      return () => clearInterval(interval);
    }

  }, []);

  return (
    <AuthContext.Provider value={{ token, role, user, rememberMe, saveUser, logout, saveToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
