const path=window.location.hostname==="register.annuariopontificio.catholic"||window.location.hostname.includes("prd") ? 'https://service.annuariopontificio.catholic' : 'https://serviceannuario.spc.va';
const vaticano=window.location.hostname==="register.annuariopontificio.catholic"||window.location.hostname.includes("prd") ? 'https://www.annuariopontificio.catholic' : 'https://viewannuario.spc.va';
const api = {
    path:path,
    vaticano:vaticano,
    createAccount:'/api/accounts/create',
    getCatalog:'/api/view/catalog',
    login:'/api/login',
    checkout:'/api/payment',
    redirectFlow:'/api/redirectFlow',
    trigger:'/api/trigger',
    info:'/api/info',
    subscription:'/api/subscription',
    plugin:'/api/view/plugin',
    captcha:'/captcha',
    captchaVerify:'/captchaverify',
    incaricogetAll:'/api/incarico',
    token:'/api/clientToken',
    userGetAll:'/api/utenti',
    delete:'/api/utente/deleted',
    search:'/api/search',
    userDetails:'/api/utente/details',
    modifyUser:'/api/utente/modify',
    free:'/api/code',
    createUserBack:'/api/accounts/back',
    updateUser:'/api/accounts/update',
    allCode:'/api/view/code',
    subscriptionDelete:'/api/delete',
    getClient:'/api/client',
    emailReject:'/api/payment/reject/email',
    checkPrice:'/api/price/check'
}

export default api;