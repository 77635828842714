import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import LoginHeader from '../header';
import Footer from '../footer';
import '../../css/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegistrationContext } from '../context/registrationProvider';
import ConfirmationDialog from '../dialog';
import CreditCardIcon from '@mui/icons-material/Payment';

const InsertDataPayment = ({ subscriptionPrice, subscriptionType }) => {
    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const [numeroCarta, setNumeroCarta] = useState('');
    const [nome, setNome] = useState('');
    const [dataScadenza, setDataScadenza] = useState('');
    const [cvv, setCvv] = useState('');
    const [errors, setErrors] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const { billingPeriod, price } = location.state || {};
      useEffect(() => {
          if (!canAccessStepRegistration) {
              navigate('/login');
          }
      }, [canAccessStepRegistration, navigate]);

    const validateForm = () => {
        let tempErrors = {};
        const namePattern = /^[a-zA-ZÀ-ÿ' ]+$/;
        const cvvPattern = /^[0-9]{3}$/; 
        const cardPattern = /^[0-9]{16}$/;

        if (!nome) {
            tempErrors.nome = "Il nome è obbligatorio";
        } else if (!namePattern.test(nome)) {
            tempErrors.nome = "Il nome può contenere solo lettere e apostrofi";
        }

        if (!numeroCarta) {
            tempErrors.numeroCarta = "Il numero della carta è obbligatorio";
        } else if (!cardPattern.test(numeroCarta)) {
            tempErrors.numeroCarta = "Il numero della carta deve essere di 16 cifre";
        }

        if (!cvv) {
            tempErrors.cvv = "Il CVV è obbligatorio";
        } else if (!cvvPattern.test(cvv)) {
            tempErrors.cvv = "Il CVV deve essere di 3 cifre numeriche";
        }

        if (!dataScadenza) {
            tempErrors.dataScadenza = "La data di scadenza è obbligatoria";
        } else {
            const [month, year] = dataScadenza.split('/').map(Number);
            if (!month || !year || month < 1 || month > 12 || year < 1 || year > 99) {
                tempErrors.dataScadenza = "Formato della data non valido (MM/AA)";
            }
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; 
    };
    const handleDataScadenzaChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); 

        if (value.length > 2) {
            const month = value.slice(0, 2);
            const year = value.slice(2, 4);
            setDataScadenza(`${month}/${year}`);
        } else {
            setDataScadenza(value);
        }
    };

    const handleSubmit = () => {
        if (validateForm()) {
            allowStepRegistrationAccess();
            if (numeroCarta.startsWith("123")) {
                navigate('/accepted');
            } else {
                navigate('/rejected');
            }
        }
    };

    const handleBackButtonClick = () => {
        setOpenDialog(true);
    };

    const handleConfirmExit = () => {
        setUserData({
            nome: '',
            cognome: '',
            dataNascita: '',
            incarico: '',
            cellulare: '',
            email: '',
            password: '',
            confirmPassword: ''
        });

        setOpenDialog(false);
        navigate('/register');
    };

    const handleCancelExit = () => {
        setOpenDialog(false);
    };

    const handleChangeSubscription = () => {
        navigate('/subscriptions'); 
    };

    return (
        <>
            <LoginHeader isRegister={true}/>
            <Box
                sx={{
                    maxWidth: 400,
                    mx: 'auto',
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 4,
                    overflowX: 'hidden'
                }}
            >
                <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 2 }}>
                    Passaggio 3 di 3
                </Typography>

                <Typography variant="h5" align="center" gutterBottom>
                    Inserisci i dati della carta
                </Typography>

                <Box component="form" noValidate sx={{ width: '100%', mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                                fullWidth
                                label="Numero della carta"
                                required
                                value={numeroCarta}
                                onChange={(e) => setNumeroCarta(e.target.value.replace(/\D/g, ''))}
                                error={!!errors.numeroCarta}
                                helperText={errors.numeroCarta}
                                inputProps={{ maxLength: 16 }} 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CreditCardIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nome sulla carta"
                                required
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                error={!!errors.nome}
                                helperText={errors.nome}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                               fullWidth
                               label="Data di Scadenza (MM/AA)"
                               required
                               value={dataScadenza}
                               onChange={handleDataScadenzaChange}
                               error={!!errors.dataScadenza}
                               helperText={errors.dataScadenza}
                               placeholder="MM/AA"
                               inputProps={{ maxLength: 5 }}
                               InputLabelProps={{
                                style: { fontSize: '16px', color: '#666', top: '5px' }
                            }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Codice di sicurezza (CVV)"
                                required
                                value={cvv}
                                onChange={(e) => setCvv(e.target.value.replace(/\D/g, ''))}
                                error={!!errors.cvv}
                                helperText={errors.cvv}
                                inputProps={{ maxLength: 3, pattern: "[0-9]*" }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            border: '1px solid #E0E0E0',
                            borderRadius: '8px',
                            padding: '10px',
                            margin: '20px auto',
                            width: '378px',
                            backgroundColor: 'white',
                            cursor: 'pointer',
                            fontFamily: 'Ruffina'
                        }}
                        onClick={() => navigate('/register/choice/plane')}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', alignItems: 'left' }}>
                            <Typography variant="body1" sx={{ fontFamily: 'Ruffina', color: '#6A6A6A' }}>
                                {price} €/{billingPeriod == 'ANNUAL' ? 'ANNO' : 'MESE'}
                            </Typography>
                            <Typography variant="body1" sx={{ fontFamily: 'Ruffina', fontSize: '14px', color: '#6A6A6A' }}>
                            {billingPeriod == 'ANNUAL' ? 'ANNUALE' : 'MENSILE'}
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ color: '#0587D1', fontFamily: 'Ruffina' }}>
                            Cambia
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column', 
                        alignItems: 'center',
                        width: '300%',
                        mt: 2,
                        marginLeft:'-400px'
                    }}>
                        <Typography variant="body1" align="left" sx={{ color: 'gray', mb: 1, fontFamily: 'Ruffina', maxWidth: '31%' }}>
                            Continuando, accetti la creazione di un account (a meno che non sia già stato creato) e accetti i nostri Termini e condizioni e l'Informativa sulla privacy.
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina', maxWidth: '31%' }}>
                            Continuando, accetti di iniziare immediatamente l’abbonamento e di non poter recedere dal contratto entro 14 giorni di calendario come previsto dal Codice del consumo italiano. Addebiteremo periodicamente la tariffa mensile o annuale.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <Button
                        variant="contained"
                        sx={{ width: '26%', color: '#00000061', background: '#0000001F' }}
                        onClick={handleBackButtonClick}
                    >
                        INDIETRO
                    </Button>

                    <Button
                        variant="contained"
                        color="warning"
                        sx={{ width: '26%', color: 'black', background: '#FDC800' }}
                        onClick={handleSubmit}
                    >
                        CONTINUA
                    </Button>
                </Box>


                    <ConfirmationDialog
                        open={openDialog}
                        onClose={handleCancelExit}
                        onConfirm={handleConfirmExit}
                        title="Uscire dalla registrazione?"
                        message="Interrompendo il processo di registrazione perderai i dati inseriti fin qui e dovrai iniziare nuovamente."
                    />
                </Box>
              
               
            </Box>
           
            <Footer />
        </>
    );
};

export default InsertDataPayment;
