import { BottomNavigationAction, Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../header';
import Footer from '../footer';
import { RegistrationContext } from '../context/registrationProvider';
import { CheckCircleOutlineOutlined, InfoOutlined } from '@mui/icons-material';
import catalogService from '../services/catalogservice';


const UseBonus = () => {

    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState({});
    const handleContinue = () => {
        allowStepRegistrationAccess();
        setUserData({
            nome: '',
            cognome: '',
            dataNascita: '',
            incarico: '',
            cellulare: '',
            email: '',
            password: '',
            confirmPassword: ''
        })
        navigate('/login');
    };

    const back = () => {
        allowStepRegistrationAccess();
        navigate('/register/choice/plane')
    }

    const go = async () => {

        try {

            const data = {
                externalKey: userData.externalId,
                accountId: userData.idAccount,
                code: code
            }

            const response = await catalogService.subsciribeFree(data);

            if (response && response.status == 201) {
                allowStepRegistrationAccess();
                navigate('/bonus/accepted');
            }

        } catch (error) {

            if (error && error.status === 400) {
                let tempErrors = {};
                tempErrors.code = 'Il codice non è corretto oppure è scaduto.';
                setErrors(tempErrors);
                return;
            }
        }
    }

    useEffect(() => {
        if (!canAccessStepRegistration) {
            navigate('/login');
        }
    }, [canAccessStepRegistration, navigate]);

    return (
        <>
            <LoginHeader  isBonus={true}/>
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pr:3,
                mt:8
            }}>
                <Box sx={{ flexGrow: 1, textAlign: 'center', mb:13 }}>
                    <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Open Sans' }}>
                        Passaggio 2 di 3
                    </Typography>
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Rufina', maxWidth: '30%', mx: 'auto', fontSize:'34px',fontWeight:'400',letterSpacing:'1px' }}>
                        Inserisci codice bonus
                    </Typography>
                    <Box component="form" noValidate sx={{
                        maxWidth: 492,
                        mx: 'auto',
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 4,
                        overflowX: 'hidden'
                    }}>
                        <Grid item xs={12}>
                            <TextField
                                name="codice"
                                label="Codice Bonus"
                                type='text'
                                fullWidth
                                margin="normal"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                error={!!errors.code}
                                helperText={errors.code}
                                placeholder='Es: 79820NB1BN'
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{width:'492px'}}
                            />

                            <Box sx={{
                                backgroundColor: '#E5F6FD',
                                borderRadius: '5px',
                                p: 2,
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb:0 }}>
                                    <InfoOutlined sx={{ color: '#0587D1', mr: 1, marginTop:'-25px'}} />
                                    <Typography variant="subtitle2" sx={{ color: '#014361' }}>
                                        Se sei in possesso di un codice bonus, inseriscilo per accedere alla tua agevolazione.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, px: 2,mb:13}}>
                            <Button
                                variant="outlined"
                                color=""
                                sx={{ width: '50%', color: 'black', background: '#white', marginRight: '280px' }}
                                onClick={back}
                            >
                                INDIETRO
                            </Button>
                            <Button
                                variant="outlined"
                                color="warning"
                                sx={{ width: '50%', color: 'black', background: '#FDC800' }}
                                onClick={go}
                            >
                                CONTINUA
                            </Button>
                        </Box>
                    </Box>

                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default UseBonus;
