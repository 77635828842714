import axios from 'axios';
import api from '../../utils/proxy.js';

const catalogService = {
    getCatalog: async () => {
        try {
            const response = await axios.post(api.path + api.getCatalog, {
                headers: {
                    'Content-Type': 'application/json',
                    
                },
            });
            return response; 
        } catch (error) {
            throw error;
        }
    },
    subsciribeFree:async (data) =>{
        try{
            const response = await axios.post(api.path + api.free, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response; 
        }catch(error){
            throw error;
        }
    },
    getAllCode:async (data,token) =>{
        try{
            const response = await axios.post(api.path + api.allCode, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response; 
        }catch(error){
            throw error;
        }
    }
};

export default catalogService;
