import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../header';
import Footer from '../footer';
import { RegistrationContext } from '../context/registrationProvider';
import { useLocation } from 'react-router-dom';


const StartRegistration = () => {

    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);
    const location = useLocation();
    const isRegister = location.state?.isRegister;

    const handleContinue = () => {
        allowStepRegistrationAccess();
        setUserData({
            nome: '',
            cognome: '',
            dataNascita: '',
            incarico: '',
            cellulare: '',
            email: '',
            password: '',
            confirmPassword: ''
        })
        navigate('/registration/user', { state: { isRegister: true } });
    };

    return (
        <>
            <LoginHeader isRegister={true} sx={{ mt: 4 }} />
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '100vh',
                    overflow: 'hidden',
                    pr:2
                }}
            >
               
                <Box
                    component="form"
                    noValidate
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginLeft: '-15px'
                    }}
                >
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{ color: 'gray', mb: 3,mt:5, fontFamily: 'Open Sans' }}
                    >
                        Passaggio 1 di 3
                    </Typography>

                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontFamily: 'Rufina',
                            maxWidth: '35%',
                            mx: 'auto',
                            mb:3,
                            fontWeight:'400',
                            fontSize:'34px',
                            lineHeight:'41.49px',
                            letterSpacing:'1px'
                        }}
                    >
                        Crea il tuo account per l'Annuario Pontificio
                    </Typography>

                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: 'gray',
                            mb: 3,
                            fontFamily: 'Open Sans',
                            maxWidth: '28%',
                            mx: 'auto',
                            fontSize: '16px',
                            letterSpacing: '0.15',
                        }}
                    >
                        Scegli una password, seleziona un piano di abbonamento ed inizia a
                        navigare
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt:4 ,mb: 10}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleContinue}
                            sx={{ background: '#FDC800', color: 'black' }}
                        >
                            CREA L'ACCOUNT
                        </Button>
                    </Box>
                </Box>

                {/* Footer */}
                <Footer sx={{ width: '100%', }} />
            </Box>
        </>

    );
};

export default StartRegistration;
