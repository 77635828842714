import axios from 'axios';
import api from '../../utils/proxy.js';

const loginService = {

    login: async (data) =>{
        try{
            const response = await axios.post(api.path + api.login, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response; 
        }catch(error){
            throw error;
        }
    }


};

export default loginService;