import React from 'react';
import { Box, Typography, Link/* , useTheme */ } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from './assets/Logo_Holy_See_v2.png';
import PropTypes from 'prop-types';
import { useAuth } from './context/authContext';
import api from '../utils/proxy';

const Header = ({ isRegister,isBonus }) => {
    const navigate = useNavigate();
    const { token } = useAuth();
    //const theme = useTheme();

    const redirectTo = () => {
        if (token) {
            navigate('/back/home');
        } else {
            navigate('/login');
        }
    };

    return (
        <>
            {/* Header Container */}
            <Box
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    margin: 0,
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'transparent',
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    height: '66px', 
                    padding: '0 32px', 
                    position: 'relative',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Link onClick={() => redirectTo()} style={{ textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: '14px', sm: '16px', md: '18px' },
                                color: 'black',
                                fontFamily: 'Rufina',
                                cursor: 'pointer',
                            }}
                        >
                            ANNUARIO PONTIFICIO
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '70px' }}>
                    {(!token || !isRegister) && (
                        <Link
                            component="button"
                            onClick={() => navigate('/register')}
                            sx={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                color: '#333',
                                textDecoration: 'none',
                                '&:hover': { textDecoration: 'underline' },
                                display: isRegister ? 'none' : 'block',
                                marginRight: '15px',
                            }}
                        >
                            Registrati
                        </Link>
                    )}
                    {!token && (
                        <Link
                            component="button"
                            onClick={() => window.location.href = api.vaticano + '/login'}
                            sx={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                color: '#333',
                                textDecoration: 'none',
                                '&:hover': { textDecoration: 'underline' },
                            }}
                        >
                            Esci
                        </Link>
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    bottom: '55px', 
                    left: {
                        xs: !isRegister && isBonus ? '48.9%' : !isRegister ? '47.4%' : !token ? '49.0%' : '49.0%', 
                        sm: !isRegister && isBonus ? '48.9%' :!isRegister ? '48.44%' : !token ? '49.0%' : '49.0%',
                        xl: !isRegister && isBonus ? '49%' :!isRegister ? '48.5%' : !token ? '49.0%' : '49.0%',
                      },
                    transform: 'translateX(-50%)',
                    width: '56px',
                    height: '64px',
                    borderRadius: '50%',
                    border: '4px solid #FDC800',
                    backgroundColor: '#FDC800',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    zIndex:1000
                }}
            >
                <Box
                    component="img"
                    src={Logo}
                    alt="Logo"
                    sx={{
                        width: '43.43px',
                        height: '48px',
                        cursor: 'pointer',
                    }}
                />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: '47px', 
                    left: '49%',
                    transform: 'translateX(-50%)', 
                    width: '71px', 
                    height: '40px', 
                    backgroundColor: '#FFFFFFE0',
                    borderBottomLeftRadius: '100% 80px', 
                    borderBottomRightRadius: '100% 80px',
                    borderLeft: '2px solid #FFFFFFE0',
                    borderRight: '2px solid #FFFFFFE0', 
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            />



            <Box
                sx={{
                    position: 'absolute',
                    left: '0%',
                    top: '47px',
                    width: '46.8%',
                    height: '2px',
                    backgroundColor: '#FFFFFFE0', 
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            />


            <Box
                sx={{
                    position: 'absolute',
                    right: '0%',
                    top: '47px',
                    width: '48.8%', 
                    height: '2px',
                    backgroundColor: '#FFFFFFE0', 
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  
                }}
            />

            <Box
                sx={{
                    height: { xs: '40px', sm: '20px', md: '0px' },
                }}
            />
        </>
    );
};

Header.propTypes = {
    isRegister: PropTypes.bool,
};

export default Header;
