import axios from 'axios';
import api from '../../utils/proxy.js';

const apiService = {
    postCaptchaRequest: async (data) => {
        try {
            const response = await axios.post(api.vaticano + api.captcha, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data; 
        } catch (error) {
            throw error;
        }
    },
    postCaptchaVerification: async (data) =>{
        try{
            const response = await axios.post(api.vaticano + api.captchaVerify, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response; 
        }catch(error){
            throw error;
        }
    }

};

export default apiService;
