import React, { createContext, useState } from 'react';

export const RegistrationContext = createContext();

export const RegistrationProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        nome: '',
        cognome: '',
        dataNascita: '',
        incarico: '',
        cellulare: '',
        email: '',
        password: '',
        confirmPassword: '',
        idAccount: '',
        idAccountBraintree: '',
        externalKey: ''
    });
    const [planData, setPlanData] = useState({
        billingPeriod: '',
        price: '',
        planName: ''
    });
    const [canAccessStepRegistration, setCanAccessStepRegistration] = useState(false);

    const allowStepRegistrationAccess = () => setCanAccessStepRegistration(true);

    return (
        <RegistrationContext.Provider value={{ canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData,planData, setPlanData }}>
            {children}
        </RegistrationContext.Provider>
    );
};
