import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import LoginPage from './login/loginPage';


const HomePage = () => {
    return (
        <LoginPage />
    );
};

export default HomePage;
