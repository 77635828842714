import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, IconButton, Box, CircularProgress, Tooltip, Pagination } from '@mui/material';
import { Edit, Delete, CheckCircleOutlineOutlined, ErrorOutlineOutlined, CancelOutlined, VisibilityOutlined } from '@mui/icons-material';
import userService from '../services/userservice';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../dialog';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const TableUser = ({ onEditClick }) => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [maxNumPage, setMaxNumPage] = useState(0);
    const { token } = useAuth() || {};
    const { saveUser, saveToken } = useAuth() || {};
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const [id, setId] = useState('');
    const [dialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const maxCaratteri = 25;

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const data = {
                    page: page + 1,
                    limit: limit,
                };

                const response = await userService.getAll(data, token);
                setData(response.users || []);
                setFilteredData(response.users || []);
                setMaxNumPage(response.maxNumPage || 0);

                if (response && response.statusCodeValue === 401) {
                    saveToken(null, null);
                    saveUser(null, false);
                    navigate('/login');
                }
            } catch (error) {
                if (error && error.response && error.response.data.statusCodeValue === 401) {
                    saveToken(null, null);
                    saveUser(null, false);
                    navigate('/login');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [page, limit, token]);

    useEffect(() => {
        if (debouncedSearchTerm.length >= 3) {
            const fetchFilteredUsers = async () => {
                setLoading(true);
                try {
                    const response = await userService.search({ search: debouncedSearchTerm }, token);
                    setFilteredData(response.users || []);
                    setMaxNumPage(response.maxNumPage || 0);

                    if (response.statusCodeValue === 401) {
                        saveToken(null, null);
                        saveUser(null, false);
                        navigate('/login');
                    }
                } catch (error) {
                    if (error && error.response && error.response.data.statusCodeValue === 401) {
                        saveToken(null, null);
                        saveUser(null, false);
                        navigate('/login');
                    }
                } finally {
                    setLoading(false);
                }
            };

            fetchFilteredUsers();
        } else {
            setFilteredData(data);
        }
    }, [debouncedSearchTerm, data, token]);

    const openDialog = (id) => {
        setId(id);
        setOpenDialog(true);
    };

    const handleDelete = async () => {
        try {
            const data = { externalKey: id, isDeleted: 1 };
            const response = await userService.delete(data, token);

            if (response && response.data && response.data.statusCodeValue === 401) {
                saveToken(null, null);
                saveUser(null, false);
                setOpenDialog(false);
                navigate('/login');
            }

            if (response.statusCodeValue === 202) {
                setOpenDialog(false);
                setData((prevData) =>
                    prevData.map((user) =>
                        user.externalKey === id ? { ...user, isDeleted: 1 } : user
                    )
                );
                setFilteredData((prevFilteredData) =>
                    prevFilteredData.map((user) =>
                        user.externalKey === id ? { ...user, isDeleted: 1 } : user
                    )
                );
            }
        } catch (error) {
            if (error && error.response && error.response.data.statusCodeValue === 401) {
                saveToken(null, null);
                saveUser(null, false);
                setOpenDialog(false);
                navigate('/login');
            }
        }
        setOpenDialog(false);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getMostRecentActiveSubscription = (subscriptions) => {
        if (!subscriptions || subscriptions.length === 0) return false;
        const mostRecent = subscriptions
            .filter(sub => sub.state === 'ACTIVE')
            .sort((a, b) => new Date(b.chargedThroughDate) - new Date(a.chargedThroughDate))[0];
        return !!mostRecent;
    };

    const handleCancelExit = () => {
        setOpenDialog(false);
    };

    return (
        <Box sx={{
            height: '100%',
            overflowY: 'auto',
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '16px',
        }}>
            <TextField
                label="Cerca"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    style: { fontSize: '16px', color: '#666', top: '5px' }
                }}
            />

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome/Cognome</TableCell>
                            <TableCell>Email/Username</TableCell>
                            <TableCell>Incarico</TableCell>
                            <TableCell>Cellulare</TableCell>
                            <TableCell>Stato Abbonamento</TableCell>
                            <TableCell>Abbonamento</TableCell>
                            <TableCell>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ fontFamily: 'Ruffina' }}>
                        {filteredData.map((user) => (
                            <TableRow key={user.accountId}>
                                {/* Nome/Cognome */}
                                <TableCell style={{ color: user.isDeleted === 1 ? 'gray' : 'black' }}>
                                    <Tooltip title={user.name} arrow>
                                        <span>
                                            {user.name && user.name.length > 25
                                                ? `${user.name.substring(0, 25)}...`
                                                : user.name}
                                        </span>
                                    </Tooltip>
                                </TableCell>

                                {/* Email/Username */}
                                <TableCell style={{ color: user.isDeleted === 1 ? 'gray' : 'black' }}>
                                    <Tooltip title={user.email || user.username} arrow>
                                        <span>
                                            {user.email && user.email.length > 25
                                                ? `${user.email.substring(0, 25)}...`
                                                : user.email || user.username}
                                        </span>
                                    </Tooltip>
                                </TableCell>

                                {/* Incarico */}
                                <TableCell style={{ color: user.isDeleted === 1 ? 'gray' : 'black' }}>
                                    <Tooltip title={user.nomeIncarico} arrow>
                                        <span>
                                            {user.nomeIncarico && user.nomeIncarico.length > 25
                                                ? `${user.nomeIncarico.substring(0, 25)}...`
                                                : user.nomeIncarico}
                                        </span>
                                    </Tooltip>
                                </TableCell>

                                {/* Cellulare */}
                                <TableCell style={{ color: user.isDeleted === 1 ? 'gray' : 'black' }}>
                                    <Tooltip title={user.cellulare || 'N/A'} arrow>
                                        <span>
                                            {user.cellulare && user.cellulare.length > 25
                                                ? `${user.cellulare.substring(0, 25)}...`
                                                : user.cellulare || 'N/A'}
                                        </span>
                                    </Tooltip>
                                </TableCell>


                                <TableCell
                                    sx={{ alignItems: 'center' }}
                                    style={{ color: user.isDeleted === 1 ? 'gray' : 'black' }}
                                >
                                    {user.isDeleted === 1 ? (
                                        <Tooltip title="Account Eliminato">
                                            <IconButton sx={{
                                                color: 'red',
                                                cursor: 'default',
                                                width: '24px',
                                                background: 'none',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            >
                                                <CancelOutlined />
                                            </IconButton>
                                        </Tooltip>

                                    ) : (
                                        <Tooltip
                                            title={
                                                getMostRecentActiveSubscription(user.subscriptions)
                                                    ? 'Abbonamento attivo'
                                                    : 'Abbonamento non attivo'
                                            }
                                            arrow
                                        >
                                            {getMostRecentActiveSubscription(user.subscriptions) ? (
                                                <CheckCircleOutlineOutlined color="success" />
                                            ) : (
                                                <ErrorOutlineOutlined color="error" />
                                            )}
                                        </Tooltip>
                                    )}
                                </TableCell>

                                <TableCell>
                                    <Tooltip
                                        title={
                                            user.isDeleted === 1
                                                ? 'Nessuno'
                                                : user?.subscriptions[0]?.productName || 'N/A'
                                        }
                                        arrow
                                    >
                                        <span>
                                            {user.isDeleted === 1
                                                ? 'Nessuno'
                                                : user?.subscriptions[0]?.productName === 'annual_subscription'
                                                    ? 'Annuale'
                                                    : user?.subscriptions[0]?.productName === 'quarterly_subscription'
                                                        ? 'Trimestrale'
                                                        : user?.subscriptions[0]?.productName === 'free_subscription'
                                                            ? 'Gratuito'
                                                            : 'N/A'}
                                        </span>
                                    </Tooltip>
                                </TableCell>

                                {/* Azioni */}
                                <TableCell>
                                    {user.isDeleted === 1 ? (
                                        <Tooltip title="Dettagli">
                                            <VisibilityOutlined
                                                sx={{ color: '#0587D1', cursor: 'pointer' }}
                                                onClick={() => onEditClick(user.externalKey)}
                                            >
                                                <Edit />
                                            </VisibilityOutlined>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            <Tooltip title="Modifica">
                                                <IconButton
                                                    sx={{ color: 'black' }}
                                                    onClick={() => onEditClick(user.externalKey)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Elimina">
                                                <IconButton
                                                    sx={{ color: 'red' }}
                                                    onClick={() => openDialog(user.externalKey)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>

                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}


            {!loading && <Pagination
                sx={{ marginTop: '30px' }}
                count={maxNumPage}
                page={page + 1}
                onChange={(event, newPage) => setPage(newPage - 1)}
            />}


            <ConfirmationDialog
                open={dialog}
                onClose={handleCancelExit}
                onConfirm={handleDelete}
                title="Attenzione"
                message="Sei sicuro di voler eliminare l'utente?"
            />
        </Box>
    );
};

export default TableUser;
